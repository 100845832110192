import React from 'react'
import { Link } from 'react-router-dom'
import './VideoStyles.css'

import spaceVideo from '../assets/casino.mp4'

const Video = () => {
    return (
        <div className='hero'>
            <video autoPlay loop muted id='video'>
                <source src={spaceVideo} type='video/mp4' />
            </video>
            <div className='content'>
                <h1 style={{border:"1px solid" , borderRadius:8, borderWidth:5}}>New Age Bet </h1><h1> Online Casino.</h1>
                <p>*** We Will Be Here Soon ... ***</p>
                <div>

                </div>
            </div>
        </div>
    )
}

export default Video
