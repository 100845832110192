import React from "react";
import "./FooterStyles.css";
import {
  FaFacebook,
  FaLinkedin,
  FaMailBulk,
  FaPhone,
  FaSearchLocation,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="location">
            <FaSearchLocation
              size={20}
              style={{ color: "#ffffff", marginRight: "2rem" }}
            />
            <div>
              <p>123 CYP St.</p>
              <h4>125, ST</h4>
            </div>
          </div>
          <div className="phone">
            <h4>
              <FaPhone
                size={20}
                style={{ color: "#ffffff", marginRight: "2rem" }}
              />{" "}
              1-800-123-1234
            </h4>
          </div>
          <div className="email">
            <h4>
              <FaMailBulk
                size={20}
                style={{ color: "#ffffff", marginRight: "2rem" }}
              />{" "}
              inqueries@newagebet.com
            </h4>
          </div>
        </div>
        <div className="right">
          <h4>About New Age Bet Casino</h4>
          <p>
            "Welcome to the New Age Bet Live Casino, Where You Get The Very Best
            of Live Casino Games. This is Where we Bring You The Ultimate Casino Experience to
            You - Wherever You are in the World."
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
